@use '@angular/material' as mat;
@import 'utilities/variables';
@import 'vendors/material/theme_variables';

$insuficient-color: rgba(var(--foreground-secondary-text-rgb), 60%);
$inconclusive-color: rgba(var(--warn-default-rgb), 80%);
$conclusive-color: var(--accent-default);

.accuracy-container {
  --insuficient-color: $insuficient-color;
  --inconclusive-color: $inconclusive-color;
  --conclusive-color: $conclusive-color;

  nouislider {
    height: 40px;
    margin-top: 62px;
    padding:0 10px;
  }

  mat-expansion-panel-header {

    mat-icon {
      &.insuficient {
        color: $insuficient-color;
      }

      &.inconclusive {
        color: $inconclusive-color;
      }

      &.conclusive {
        color: $conclusive-color;
      }
    }
  }

  .noUi-handle {
    border-radius: 50%;
    cursor: move;
    border-color: var(--primary-default-contrast);
    background-color: var(--primary-default);
    box-shadow: none;

    &:after, &:before {
      display: none;
    }
  }

  .noUi-horizontal {
    height: 6px;

    .noUi-handle {
      width: 18px;
      height: 18px;
      right: -9px;
      top: -7px;
    }
  }

  .noUi-tooltip {
    border: none;
    border-radius: 0;
    background: transparent;
    color: var(--primary-default-contrast);
    font-size: $smaller-font;
    font-weight: 500;
    margin-bottom: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      background: var(--primary-default);
      z-index: -1;
      border-radius: 100%;
      border-bottom-right-radius: 0;
      transform: rotate(45deg);
      margin-left: -15px;
      margin-top: -15px;
    }
  }

  .noUi-connect:nth-child(1) {
    background-color: $insuficient-color;
  }

  .noUi-connect:nth-child(2) {
    background-color: $inconclusive-color;
  }

  .noUi-connect:nth-child(3) {
    background-color: $conclusive-color;
  }

  .noUi-marker-large {
    height: 8px;
  }

  .noUi-value {
    margin-top: 4px;
    font-size: $smaller-font;
    font-weight: bold;
  }
}


.bot-event-intervals-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.add-interval {
    margin: 1rem 0;

    .simple-input {
      border-style: dashed;
      border-width: 2px;
    }
  }
}


.bot-event-intervals-container.cdk-drop-list-dragging .bot-event-intervals-interval-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.bot-event-intervals-interval-row {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: var(--background);
  border-radius: var(--border-radius);
  overflow: hidden;

  input {
    width: 100%;
    min-width: 0;
    height: auto !important;
  }

  .int-suffix {
    padding: 2px 4px;
    border-radius: var(--border-radius);
    font-size: 12px;
    font-weight: bold;
    background-color: var(--foreground-primary-text);
    background-color: rgba(var(--foreground-color-rgb),.1);
  }

  .l-dg-start {
    cursor: grab;
    width: 32px; // tamanho minimo para botao - nando help tips #1
    height: 32px;
  }

  .l-dg-start,
  .rm-btn {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  app-timestamp-picker {
    width: 100%;
  }

  &.cdk-drag-preview {
    outline: 2px solid var(--accent-default);
    outline-offset: 2px;
  }
  
  &.cdk-drag-placeholder {
    opacity: 0;
  }
  
  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  

}