@import 'utilities/variables';

nav {
	color: $white;
	background-color: $nav-bar-blue;
	font-size: 15px;
	height: $navbar-height;
	margin: 0;
    padding: 3px;

    .title {
        margin: 0 10px;
        font-size: 10px;  
        font-size: 23px;
        color: $white;
        text-align: center;
        display: flex;

	    .back-button {
	        background-size: 13px;
	        background-repeat: no-repeat;
	        background-position: center center;
	        position: relative;
	        z-index: $zIndexLayer5;
	        margin-left: 10px;
	        opacity: 0.4;
	        width: 60px;
	        height: 34px;
	        -webkit-transition: all 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
	        transition: all 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
	        cursor: pointer;

	        &:hover{
	          opacity: 0.9;
	        }
	    }
    }

}
