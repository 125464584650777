.toolbox-container {
  &.left {
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        padding: 0 6px !important;
        line-height: 32px !important;
      }
    }
  }
}

.legacy-tooltip {
  font-family: 'Roboto', sans-serif;
}

.element-container {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  border-radius: 4px;
  background-color: white;

  &.drop-aimed {
    box-shadow: none !important;
    pointer-events: none;

    &.allowed {
      outline: 3px dashed var(--accent-500) !important;
      outline-color: var(--accent-500);

      &:after {
        opacity: 1;
      }
    }

    &.not-allowed {
      filter: grayscale(1) brightness(0.8);
      opacity: 0.4;
    }
  }

  &:after {
    transition: opacity 0.6s;
    box-shadow: 0 0 0 2px rgba(var(--accent-500-rgb), 20%),
      0 0 30px -10px var(--accent-500);
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--border-radius);
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
  }

  &.external {
    .external-element-button {
      font-family: "Material Icons";
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      background-color: #0e2b25;
      border-radius: 0 6px 6px 0;

      &[disabled] {
        cursor: initial;
      }
    }

    &:hover > .legacy-tooltip {
      visibility: visible;
      z-index: 1;
      opacity: 1;
    }
  }

  &.rf-edge-loading {
    pointer-events: none;
    opacity: 0.2;
  }

  .drag-handle {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    width: 25px;
    background-color: #1F4E44;
    border-radius: 6px 0 0 6px;
    margin: -1px; // fixes a bug where a white padding appears between this and the container element

    .icon {
      width: 10px;
    }
  }

  .drag-handle-content {
    display: flex;
    flex-direction: column;
    max-width: 315px;
    padding: 9px 10px 8px 4px;
    cursor: initial;

    &:has(.content.body) {
      padding: 6px 10px 6px 4px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    padding: 0 6px;
    align-items: center;

    &.top {
      .title-icon {
        width: 22px;
        height: 22px;
        margin: 0 6px 0 0;
        flex-shrink: 0;
      }

      .title {
        margin: 0;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
        font-size: 12px;
      }

      .sequencial-position {
        flex-shrink: 0;
        background: var(--primary-500);
        color: var(--primary-A200);
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        font-size: 12px;
        font-weight: bold;
        margin: 2px 6px 2px 2px;
      }
    }

    &.body {
      .next-step-container {
        display: flex;
        align-items: center;
        gap: 8px;
        max-width: 100%;
        color: white;
        font-size: 10px;
        background-color: #0e2b25;
        border-radius: 30px;
        padding-right: 8px;
        margin-top: 10px;

        .next-step-icon {
          display: flex;
          align-items: center;
          border-radius: 30px;
          background-color: #069177;
          padding-right: 10px;
        }

        .next-step-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        svg {
          height: 20px;
          width: 26px;

          .play-icon { fill: #99EFDF; }
          .background-arrow { fill: #0E2B25; }
        }

        img.title-icon {
          height: 14px;
          width: 14px;
          filter: invert(1);
          margin-left: 4px;
        }
      }
    }

    &.bottom {
      .item-list-container {
        position: relative;

        .item-list {
          position: relative;
          float: left;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          color: var(--primary-50-contrast);
          margin-top: 9px;
          margin-right: 14px;
          line-height: 85%;
          display: flex;
          justify-content: center;
          align-items: center;

          &.clickable {
            cursor: pointer;
          }

          &.edit-icon {
            background: var(--primary-default);
            color: var(--primary-default-contrast);

            .material-icons {
              font-size: 12px;
            }
          }

          .asset-icon {
            height: 16px;
            width: 16px;
          }

          .item-count {
            position: absolute;
            top: -1px;
            right: -2px;
            display: inline-block;
            width: 10px;
            height: 10px;
            font-family: 'Roboto', sans-serif;
            font-size: 8px;
            line-height: 6px;
            text-align: center;
            white-space: nowrap;
            padding: 2px 1px;
            background-color: var(--accent-500);
            border-radius: 50%;
          }
        }

        &:not(:last-child)::after {
          content: '';
          width: 1px;
          height: 10px;
          background-color: #000000;
          position: absolute;
          top: 16px;
          right: 6.5px;
        }

        &:hover {
          .legacy-tooltip {
            visibility: visible;
            z-index: 1;
            opacity: 1;
          }
        }
      }

      .bottom-title {
        margin: .5px 0;
        font-size: 10px;
      }

      .bottom-icon {
        width: 16px;
        height: 16px;
        margin: 0px 9px 0 3px;
      }
    }
  }
}

.element-container.botRoot:not(.external)  {
  color: white;
  background-color: #0E2B25;
  border-color: #424242;

  .drag-handle {
    width: 24px;
    margin: 0;
    background-color: #000000;
  }

  .content.top .title-icon {
    filter: invert(1);
  }

  .content.body .next-step-container {
    background-color: black;

    .next-step-icon {
      background-color: #0e2b25;
    }

    svg {
      .play-icon { fill: #00d6ae; }
      .background-arrow { fill: #0a1e1a; }
    }

    .title-icon {
      filter: invert(1);
    }
  }

  .content.bottom {
    .asset-icon {
      filter: invert(1);
    }

    .item-list-container::after {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.element-container.external {
  .content.bottom {
    margin-top: 2px;
  }

  &.botRoot {
    border-color: #d96f48;

    .drag-handle {
      background-color: #d96f48;
    }
  }

  &.formTransaction, &.nlpTransaction {
    .content.top .title-icon {
      height: 20px;
    }
  }

  &.formTransaction  {
    border-color: #9c9900;

    .drag-handle {
      background-color: #9c9900;
    }
  }

  &.nlpTransaction {
    border-color: #1a81db;

    .drag-handle {
      background-color: #1a81db;
    }
  }
}

.diagram-editor-container {
  &.performing-update {
    cursor: wait;

    * {
      pointer-events: none !important;
    }
  }
}

app-bpm-graph-viewer {
  .dashboard-page {
    max-width: 100%;
  }
}

.element-container {
  &.bpmMKT {
    border-radius: 18px;
    box-shadow: 0 0 0px 2px #00f2a142;

    .drag-handle {
      border-radius: 18px 0 0 18px;
      flex-basis: 32px;
    }

    .drag-handle-content {
      padding: 5px 14px 5px 5px;
      border-radius: 0 18px 18px 0;
    }
  }
}

.standard-reactflow-node-wrapper,
.nestedAIRoot-reactflow-node-wrapper {
  max-width: 340px;

  > div {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    border: 2px solid #1F4E44;
    border-radius: 8px;
  }
}

.nestedAIRoot-reactflow-node-wrapper {
  .element-container {
    flex-direction: column;
  }

  .node-type {
    text-align: center;
    padding: 4px;
    background-color: var(--primary-default);
    color: var(--primary-default-contrast);
    border-radius: 4px 4px 0 0;
    font-size: 0.6rem;
  }

  .node-header {
    position: relative;
    padding: 4px 2px 0 2px;
  }

  .content {
    &.body {
      padding: 16px 8px;
      border-bottom: 5px solid var(--primary-default);
      border-radius: 4px;
      flex-direction: column;

      ul.intent-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        margin: 0;

        li {
          position: relative;

          .intent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            width: 200px;
            height: 60px;
            padding: 8px 4px;
            border: 1px solid var(--primary-100);
            box-shadow: 0px 2px 6px rgb(0 0 0 / 18%);
            outline: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            cursor: pointer;

            .intent-name {
              display: block;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
              font-size: 0.8rem;
            }

            ul.content-list {
              display: flex;
              justify-content: center;
              overflow: hidden;
              width: 170px;
              margin: 0 auto;
              gap: 8px;

              li {
                position: relative;
                margin-top: 6px;

                i {
                  font-size: 15px;
                  width: fit-content;
                  height: fit-content;
                  border: 1px dotted var(--primary-200);
                  border-radius: 50%;
                  padding: 2px;
                }

                .custom-badge {
                  position: absolute;
                  top: -6px;
                  right: -6px;
                  background-color: var(--warn-500);
                  border-radius: 50%;
                  font-size: 8px;
                  width: 12px;
                  height: 12px;
                  line-height: 12px;
                  color: white;
                  padding: 1px;
                }
              }
            }
          }
        }
      }

      .btn-show {
        position: relative;
        width: 200px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.less {
          .material-icons {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.react-flow__node.react-flow__node-standard,
.react-flow__node.react-flow__node-nestedAIRoot {
  &.selectable {
    &.selected {
      .standard-reactflow-node-wrapper,
      .nestedAIRoot-reactflow-node-wrapper {
        > div {
          box-shadow: 0 0 0px 4px rgba(var(--accent-700-contrast-rgb), 10%);
          outline: 2px solid var(--accent-500);
        }
      }
    }
  }

  &.bpm-el-crud-Save,
  &.bpm-el-crud-Remove {
    pointer-events: none !important;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      z-index: 100;
      background: #ffffffc7;
      border-radius: var(--border-radius);
    }

    &:after {
      width: 22px;
      height: 22px;
      z-index: 101;
      animation: blink 1s linear infinite;
      font-family: "Material Icons";
      font-feature-settings: "liga";
      font-size: 22px;
      line-height: 22px;
    }
  }

  &.bpm-el-crud-Save {
    &:after {
      content: "";
      border: 2px solid var(--primary-300);
      width: 20px;
      height: 20px;
      border-bottom-color: transparent;
      animation: spin_centered 1s linear infinite;
      border-radius: 100%;
    }
  }

  &.bpm-el-crud-Remove {
    &:after {
      content: "delete";
      color: var(--warn-default);
    }
  }
}

.reactflow-container {
  position: relative;
  height: 100%;
  // background: #fafefe;
  // background: #f5fbfb;
  background: #f1f5f6;
}

.react-flow__edge {
  &.selected {
    .react-flow__edge-interaction {
      stroke: rgb(0 231 137 / 30%);
      stroke-width: 6px;
      stroke-opacity: 1;
    }
  }

  &.rf-edge-crud-Save {
    animation: blink 1s linear infinite;
  }

  &.rf-edge-crud-Remove {
    animation: blink 1s linear infinite;

    > .react-flow__edge-path {
      stroke: rgba(var(--warn-500-rgb), 40%) !important;
    }
  }
}

.react-flow__attribution {
  display: none;
}

.react-flow__selection {
  background: rgba(var(--accent-default-rgb), 20%) !important;
  border-color: var(--accent-default) !important;
}

.rf-cb-disabled {
  opacity: 0.2;
}

.react-flow__handle {
  &.idParent {
    border-radius: 0;
    border: none;
    background: #888888;

    &.source {
      height: 2px;
      min-height: unset;
    }

    &.target {
      width: 2px;
      min-width: unset;
    }
  }

  &.transaction,
  &.goActionTree,
  &.actType\.goBot {
    --color: var(--accent-500);
    width: 4px;
    height: 4px;
    min-width: unset;
    min-height: unset;
    border-radius: 2px;
    border: 0;
    border-radius: 20%;
    background: transparent;
    opacity: 0.1;
    outline: 2px solid var(--color);
    filter: grayscale(1);
    right: -2px;

    &.has-connection {
      opacity: 1;
      z-index: 4;
      box-shadow: 0 0 14px 1px var(--color), inset 0 0 0 4px var(--color);
      filter: none;
    }

    &.react-flow__handle-top {
      top: -2px;
    }

    &.react-flow__handle-left {
      left: -2px;
    }

    &.react-flow__handle-right {
      right: -2px;
    }

    &.react-flow__handle-bottom {
      bottom: -2px;
    }
  }

  &.actType\.goBot {
    --color: rgb(0, 178, 255);
  }
}

.rf-intention-connection,
.rf-con-intention-circle {
  stroke: #888;

  &.valid {
    stroke: var(--success-500);
  }

  &.invalid {
    stroke: var(--warn-500);
  }
}

.rf-intention-bg {
  &.valid {
    stroke: rgba(var(--success-500-rgb), 20%);
  }

  &.invalid {
    stroke: rgba(var(--warn-500-rgb), 20%);
  }
}

.rf-con-intention-circle {
  fill: #888;

  &.valid {
    fill: var(--success-500);
  }

  &.invalid {
    fill: var(--warn-500);
  }
}

.rf-diagram-tool-button {
  position: relative;
}

.bpmMKT {
  .react-flow__handle {
    &.idParent {
      background: var(--accent-500);
      height: 2px;
      min-height: unset;
      width: 6px;
      border-radius: 4px;
      opacity: 0;

      &.has-connection {
        opacity: 1;
      }
    }
  }
}

.react-flow__edge.animated.reverse-animation path {
  animation-direction: reverse;
}

.blockUIDiagram {
  .react-flow__node,
  .react-flow__node *,
  .react-flow__edge,
  .react-flow__edge * {
    pointer-events: none !important;
    cursor: wait !important;
  }
}
