@use '@angular/material' as mat;
@use 'sass:color';


@function get-material-pallet-from-css($name) {
  @return ((50: var(--#{$name}-50),
      100: var(--#{$name}-100),
      200: var(--#{$name}-200),
      300: var(--#{$name}-300),
      400: var(--#{$name}-400),
      500: var(--#{$name}-500),
      600: var(--#{$name}-600),
      700: var(--#{$name}-700),
      800: var(--#{$name}-800),
      900: var(--#{$name}-900),
      A100: var(--#{$name}-A100),
      A200: var(--#{$name}-A200),
      A400: var(--#{$name}-A400),
      A700: var(--#{$name}-A700),
      contrast: (50: var(--#{$name}-50-contrast),
        100: var(--#{$name}-100-contrast),
        200: var(--#{$name}-200-contrast),
        300: var(--#{$name}-300-contrast),
        400: var(--#{$name}-400-contrast),
        500: var(--#{$name}-500-contrast),
        600: var(--#{$name}-600-contrast),
        700: var(--#{$name}-700-contrast),
        800: var(--#{$name}-800-contrast),
        900: var(--#{$name}-900-contrast),
        A100: var(--#{$name}-A100-contrast),
        A200: var(--#{$name}-A200-contrast),
        A400: var(--#{$name}-A400-contrast),
        A700: var(--#{$name}-A700-contrast),
      )));
}

@function toRGBRaw ($color) {
  @return red($color)+", "+green($color)+", "+blue($color);
}

@mixin get-color-vars($color-name, $color-ton, $color-code) {
  --#{$color-name}-#{$color-ton}: #{$color-code};
  --#{$color-name}-#{$color-ton}-rgb: #{toRGBRaw($color-code)};
}

.colmeia-light-theme {

  --background: white;
  --font-family: #{$font-family};
  --background-rgb: 255, 255, 255;
  --foreground-color-rgb: 0, 30, 30;
  --foreground-color: rgb(var(--foreground-color-rgb));

  --foreground-background: var(--background);
  --foreground-background-rgb: var(--background-rgb);
  --foreground-base: rgb(var(--foreground-color-rgb));
  --foreground-base-rgb: var(--foreground-color-rgb);
  --foreground-divider: rgba(var(--foreground-color-rgb), 0.14);
  --foreground-divider-rgb: var(--foreground-color-rgb);
  --foreground-surface: rgba(var(--foreground-color-rgb), 0.14);
  --foreground-surface-rgb: var(--foreground-color-rgb);
  --foreground-dividers: rgba(var(--foreground-color-rgb), 0.52);
  --foreground-dividers-rgb: var(--foreground-color-rgb);
  --foreground-disabled: rgba(var(--foreground-color-rgb), 0.38);
  --foreground-disabled-rgb: var(--foreground-color-rgb);
  --foreground-disabled-button: rgba(var(--foreground-color-rgb), 0.38);
  --foreground-disabled-button-rgb: var(--foreground-color-rgb);
  --foreground-disabled-text: rgba(var(--foreground-color-rgb), 0.38);
  --foreground-disabled-text-rgb: var(--foreground-color-rgb);
  --foreground-hint-text: rgba(var(--foreground-color-rgb), 0.38);
  --foreground-hint-text-rgb: var(--foreground-color-rgb);
  --foreground-secondary-text: rgba(var(--foreground-color-rgb), 0.7);
  --foreground-secondary-text-rgb: var(--foreground-color-rgb);
  --foreground-icon: rgba(var(--foreground-color-rgb), 0.8);
  --foreground-icon-rgb: var(--foreground-color-rgb);
  --foreground-icons: rgba(var(--foreground-color-rgb), 0.8);
  --foreground-icons-rgb: var(--foreground-color-rgb);
  --foreground-text: rgba(var(--foreground-color-rgb), 0.9);
  --foreground-text-rgb: var(--foreground-color-rgb);
  --foreground-slider-off: rgba(var(--foreground-color-rgb), 0.26);
  --foreground-slider-off-rgb: var(--foreground-color-rgb);
  --foreground-slider-off-active: rgba(var(--foreground-color-rgb), 0.38);
  --foreground-slider-off-active-rgb: var(--foreground-color-rgb);
  --foreground-slider-min: rgb(var(--foreground-color-rgb));
  --foreground-slider-min-rgb: var(--foreground-color-rgb);
  --foreground-modal-bg: var(--background);
  --foreground-modal-bg-rgb: var(--background-rgb);

  @include get-color-vars('primary', '50', #e1e3e3);
  @include get-color-vars('primary', '100', #b5bab9);
  @include get-color-vars('primary', '200', #838c8b);
  @include get-color-vars('primary', '300', #515d5c);
  @include get-color-vars('primary', '400', #2c3b39);
  @include get-color-vars('primary', '500', #071816);
  @include get-color-vars('primary', '600', #061513);
  @include get-color-vars('primary', '700', #051110);
  @include get-color-vars('primary', '800', #040e0c);
  @include get-color-vars('primary', '900', #020806);
  @include get-color-vars('primary', 'A100', #4effb7);
  @include get-color-vars('primary', 'A200', #1bffa3);
  @include get-color-vars('primary', 'A400', #00e789);
  @include get-color-vars('primary', 'A700', #00ce7a);

  @include get-color-vars('primary', '50-contrast', #000000);
  @include get-color-vars('primary', '100-contrast', #000000);
  @include get-color-vars('primary', '200-contrast', #000000);
  @include get-color-vars('primary', '300-contrast', #ffffff);
  @include get-color-vars('primary', '400-contrast', #ffffff);
  @include get-color-vars('primary', '500-contrast', #ffffff);
  @include get-color-vars('primary', '600-contrast', #ffffff);
  @include get-color-vars('primary', '700-contrast', #ffffff);
  @include get-color-vars('primary', '800-contrast', #ffffff);
  @include get-color-vars('primary', '900-contrast', #ffffff);
  @include get-color-vars('primary', 'A100-contrast', #015036);
  @include get-color-vars('primary', 'A200-contrast', #015036);
  @include get-color-vars('primary', 'A400-contrast', #015036);
  @include get-color-vars('primary', 'A700-contrast', #015036);

  @include get-color-vars('primary', 'default', #071816);
  @include get-color-vars('primary', 'lighter', #b5bab9);
  @include get-color-vars('primary', 'darker', #051110);
  @include get-color-vars('primary', 'text', #020806);
  @include get-color-vars('primary', 'default-contrast', #ffffff);
  @include get-color-vars('primary', 'lighter-contrast', #e1e3e3);
  @include get-color-vars('primary', 'darker-contrast', #020806);



  @include get-color-vars('accent', '50', #e0faf5);
  @include get-color-vars('accent', '100', #b3f3e7);
  @include get-color-vars('accent', '200', #80ebd7);
  @include get-color-vars('accent', '300', #4de2c6);
  @include get-color-vars('accent', '400', #26dcba);
  @include get-color-vars('accent', '500', #00d6ae);
  @include get-color-vars('accent', '600', #00d1a7);
  @include get-color-vars('accent', '700', #00cc9d);
  @include get-color-vars('accent', '800', #00c694);
  @include get-color-vars('accent', '900', #00bc84);
  @include get-color-vars('accent', 'A100', #e5fff6);
  @include get-color-vars('accent', 'A200', #b2ffe5);
  @include get-color-vars('accent', 'A400', #7fffd3);
  @include get-color-vars('accent', 'A700', #65ffcb);

  @include get-color-vars('accent', '50-contrast', #000000);
  @include get-color-vars('accent', '100-contrast', #000000);
  @include get-color-vars('accent', '200-contrast', #000000);
  @include get-color-vars('accent', '300-contrast', #000000);
  @include get-color-vars('accent', '400-contrast', #000000);
  @include get-color-vars('accent', '500-contrast', #002b23);
  @include get-color-vars('accent', '600-contrast', #000000);
  @include get-color-vars('accent', '700-contrast', #04402d);
  @include get-color-vars('accent', '800-contrast', #000000);
  @include get-color-vars('accent', '900-contrast', #ffffff);
  @include get-color-vars('accent', 'A100-contrast', #000000);
  @include get-color-vars('accent', 'A200-contrast', #000000);
  @include get-color-vars('accent', 'A400-contrast', #000000);
  @include get-color-vars('accent', 'A700-contrast', #000000);


  @include get-color-vars('accent', 'default', #00d6ae);
  @include get-color-vars('accent', 'lighter', #b3f3e7);
  @include get-color-vars('accent', 'darker', #00bc84);
  @include get-color-vars('accent', 'text', #00bc84);
  @include get-color-vars('accent', 'default-contrast', #002b23);
  @include get-color-vars('accent', 'lighter-contrast', #000000);
  @include get-color-vars('accent', 'darker-contrast', #ffffff);
  // --accent-default: var(--accent-500);
  // --accent-lighter: var(--accent-100);
  // --accent-darker: var(--accent-700);
  // --accent-text: var(--accent-500);
  // --accent-default-contrast: var(--accent-500-contrast);
  // --accent-lighter-contrast: var(--accent-50-contrast);
  // --accent-darker-contrast: var(--accent-900-contrast);


  @include get-color-vars('success', '50', #e2fbee);
  @include get-color-vars('success', '100', #b7f5d5);
  @include get-color-vars('success', '200', #88efb9);
  @include get-color-vars('success', '300', #58e99c);
  @include get-color-vars('success', '400', #34e487);
  @include get-color-vars('success', '500', #10df72);
  @include get-color-vars('success', '600', #0edb6a);
  @include get-color-vars('success', '700', #0cd75f);
  @include get-color-vars('success', '800', #09d255);
  @include get-color-vars('success', '900', #22ff66);
  @include get-color-vars('success', 'A100', #f3fff6);
  @include get-color-vars('success', 'A200', #c0ffd0);
  @include get-color-vars('success', 'A400', #8dffaa);
  @include get-color-vars('success', 'A700', #74ff97);

  @include get-color-vars('success', '50-contrast', #015f20);
  @include get-color-vars('success', '100-contrast', #015f20);
  @include get-color-vars('success', '200-contrast', #015f20);
  @include get-color-vars('success', '300-contrast', #015f20);
  @include get-color-vars('success', '400-contrast', #015f20);
  @include get-color-vars('success', '500-contrast', #015f20);
  @include get-color-vars('success', '600-contrast', #015f20);
  @include get-color-vars('success', '700-contrast', #015f20);
  @include get-color-vars('success', '800-contrast', #015f20);
  @include get-color-vars('success', '900-contrast', #015f20);
  @include get-color-vars('success', 'A100-contrast', #015f20);
  @include get-color-vars('success', 'A200-contrast', #015f20);
  @include get-color-vars('success', 'A400-contrast', #015f20);
  @include get-color-vars('success', 'A700-contrast', #015f20);


  @include get-color-vars('success', 'default', #10df72);
  @include get-color-vars('success', 'lighter', #b7f5d5);
  @include get-color-vars('success', 'darker', #0cd75f);
  @include get-color-vars('success', 'text', #10df72);
  @include get-color-vars('success', 'default-contrast', #015f20);
  @include get-color-vars('success', 'lighter-contrast', #015f20);
  @include get-color-vars('success', 'darker-contrast', #015f20);





  @include get-color-vars('info', '50', #e8f9fa);
  @include get-color-vars('info', '100', #c6f0f2);
  @include get-color-vars('info', '200', #a0e7ea);
  @include get-color-vars('info', '300', #7adde2);
  @include get-color-vars('info', '400', #5ed5db);
  @include get-color-vars('info', '500', #41ced5);
  @include get-color-vars('info', '600', #3bc9d0);
  @include get-color-vars('info', '700', #32c2ca);
  @include get-color-vars('info', '800', #2abcc4);
  @include get-color-vars('info', '900', #1c66ba);
  @include get-color-vars('info', 'A100', #f1feff);
  @include get-color-vars('info', 'A200', #befbff);
  @include get-color-vars('info', 'A400', #8bf7ff);
  @include get-color-vars('info', 'A700', #71f5ff);

  @include get-color-vars('info', '50-contrast', #001516);
  @include get-color-vars('info', '100-contrast', #001516);
  @include get-color-vars('info', '200-contrast', #001516);
  @include get-color-vars('info', '300-contrast', #001516);
  @include get-color-vars('info', '400-contrast', #001516);
  @include get-color-vars('info', '500-contrast', #001516);
  @include get-color-vars('info', '600-contrast', #001516);
  @include get-color-vars('info', '700-contrast', #001516);
  @include get-color-vars('info', '800-contrast', #001516);
  @include get-color-vars('info', '900-contrast', #001516);
  @include get-color-vars('info', 'A100-contrast', #001516);
  @include get-color-vars('info', 'A200-contrast', #001516);
  @include get-color-vars('info', 'A400-contrast', #001516);
  @include get-color-vars('info', 'A700-contrast', #001516);

  @include get-color-vars('info', 'default', #41ced5);
  @include get-color-vars('info', 'lighter', #c6f0f2);
  @include get-color-vars('info', 'darker', #1c66ba);
  @include get-color-vars('info', 'text', #1c66ba);
  @include get-color-vars('info', 'default-contrast', #001516);
  @include get-color-vars('info', 'lighter-contrast', #001516);
  @include get-color-vars('info', 'darker-contrast', #001516);

  @include get-color-vars('alert', '50', #fdfce9);
  @include get-color-vars('alert', '100', #fbf7c7);
  @include get-color-vars('alert', '200', #f8f1a2);
  @include get-color-vars('alert', '300', #f5eb7d);
  @include get-color-vars('alert', '400', #f2e761);
  @include get-color-vars('alert', '500', #f0e345);
  @include get-color-vars('alert', '600', #eee03e);
  @include get-color-vars('alert', '700', #ecdc36);
  @include get-color-vars('alert', '800', #e9d82e);
  @include get-color-vars('alert', '900', #e5d01f);
  @include get-color-vars('alert', 'A100', #ffffff);
  @include get-color-vars('alert', 'A200', #fffce8);
  @include get-color-vars('alert', 'A400', #fff7b5);
  @include get-color-vars('alert', 'A700', #fff49c);

  @include get-color-vars('alert', '50-contrast', #4d4701);
  @include get-color-vars('alert', '100-contrast', #4d4701);
  @include get-color-vars('alert', '200-contrast', #4d4701);
  @include get-color-vars('alert', '300-contrast', #4d4701);
  @include get-color-vars('alert', '400-contrast', #4d4701);
  @include get-color-vars('alert', '500-contrast', #4d4701);
  @include get-color-vars('alert', '600-contrast', #4d4701);
  @include get-color-vars('alert', '700-contrast', #4d4701);
  @include get-color-vars('alert', '800-contrast', #4d4701);
  @include get-color-vars('alert', '900-contrast', #4d4701);
  @include get-color-vars('alert', 'A100-contrast', #4d4701);
  @include get-color-vars('alert', 'A200-contrast', #4d4701);
  @include get-color-vars('alert', 'A400-contrast', #4d4701);
  @include get-color-vars('alert', 'A700-contrast', #4d4701);

  @include get-color-vars('alert', 'default', #f0e345);
  @include get-color-vars('alert', 'lighter', #fbf7c7);
  @include get-color-vars('alert', 'darker', #e5d01f);
  @include get-color-vars('alert', 'text', #4d4701);
  @include get-color-vars('alert', 'default-contrast', #4d4701);
  @include get-color-vars('alert', 'lighter-contrast', #4d4701);
  @include get-color-vars('alert', 'darker-contrast', #4d4701);

  @include get-color-vars('warn', '50', #FFEBEE);
  @include get-color-vars('warn', '100', #FFCDD2);
  @include get-color-vars('warn', '200', #EF9A9A);
  @include get-color-vars('warn', '300', #E57373);
  @include get-color-vars('warn', '400', #EF5350);
  @include get-color-vars('warn', '500', #F44336);
  @include get-color-vars('warn', '600', #E53935);
  @include get-color-vars('warn', '700', #D32F2F);
  @include get-color-vars('warn', '800', #C62828);
  @include get-color-vars('warn', '900', #B71C1C);
  @include get-color-vars('warn', 'A100', #ffffff);
  @include get-color-vars('warn', 'A200', #ffd3cd);
  @include get-color-vars('warn', 'A400', #ffa69a);
  @include get-color-vars('warn', 'A700', #ff8f80);

  @include get-color-vars('warn', '50-contrast', #2c0f02);
  @include get-color-vars('warn', '100-contrast', #2c0f02);
  @include get-color-vars('warn', '200-contrast', #2c0f02);
  @include get-color-vars('warn', '300-contrast', #2c0f02);
  @include get-color-vars('warn', '400-contrast', #2c0f02);
  @include get-color-vars('warn', '500-contrast', #ffffff);
  @include get-color-vars('warn', '600-contrast', #ffffff);
  @include get-color-vars('warn', '700-contrast', #ffffff);
  @include get-color-vars('warn', '800-contrast', #ffffff);
  @include get-color-vars('warn', '900-contrast', #ffffff);
  @include get-color-vars('warn', 'A100-contrast', #2c0f02);
  @include get-color-vars('warn', 'A200-contrast', #2c0f02);
  @include get-color-vars('warn', 'A400-contrast', #2c0f02);
  @include get-color-vars('warn', 'A700-contrast', #2c0f02);

  @include get-color-vars('warn', 'default', #F44336);
  @include get-color-vars('warn', 'lighter', #FFCDD2);
  @include get-color-vars('warn', 'darker', #B71C1C);
  @include get-color-vars('warn', 'text', #B71C1C);
  @include get-color-vars('warn', 'default-contrast', #ffffff);
  @include get-color-vars('warn', 'lighter-contrast', #2c0f02);
  @include get-color-vars('warn', 'darker-contrast', #ffffff);

}

$md-primary-light: get-material-pallet-from-css('primary');
$md-accent-light: get-material-pallet-from-css('accent');
$md-success-light: get-material-pallet-from-css('success');
$md-info-light: get-material-pallet-from-css('info');
$md-alert-light: get-material-pallet-from-css('alert');
$md-warn-light: get-material-pallet-from-css('warn');


@function theme-foreground-pallete-builder($color) {
  @return (background: white,
    base: $color,
    divider: rgba($color, 0.14),
    surface: rgba($color, 0.14),
    dividers: rgba($color, 0.52),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.7),
    icon: rgba($color, 0.8),
    icons: rgba($color, 0.8),
    text: rgba($color, 0.9),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
    slider-min: $color,
    modal-bg: #fff // base:              black,
    // divider:           $dark-dividers,
    // dividers:          $dark-dividers,
    // disabled:          $dark-disabled-text,
    // disabled-button:   rgba(black, 0.26),
    // disabled-text:     $dark-disabled-text,
    // hint-text:         $dark-disabled-text,
    // secondary-text:    $dark-secondary-text,
    // icon:              rgba(black, 0.54),
    // icons:             rgba(black, 0.54),
    // text:              rgba(black, 0.87),
    // slider-min:        rgba(black, 0.87),
    // slider-off:        rgba(black, 0.26),
    // slider-off-active: rgba(black, 0.38),

  );
}

$colmeia-light-foreground: theme-foreground-pallete-builder(rgb(0, 30, 30));
$colmeia-app-typography: mat.define-typography-config($font-family: $font-family);

// @debug mat.$indigo-palette;

$primary-light: mat.define-palette($md-primary-light, $default: 500, $lighter: 100, $darker: 700);
$accent-light: mat.define-palette($md-accent-light);
$warn-light: mat.define-palette($md-warn-light);
$info-light: mat.define-palette($md-info-light);
$success-light: mat.define-palette($md-success-light);
$alert-light: mat.define-palette($md-alert-light);
$app-bar-bg-color: mat.get-color-from-palette($primary-light, 900);
$box-elevation-1: 0 0 0 2px mat.get-color-from-palette($colmeia-light-foreground, divider);

$colmeia-light-theme: mat.define-light-theme((color: (primary: $primary-light,
        accent: $accent-light,
        warn: $warn-light,
      ),
      typography: mat.define-typography-config($font-family: $font-family),
      density: 0,
    ));