@use '@angular/material' as mat;
@use 'sass:color';

.ordenable-list {
  width: 100%;
  border: solid 2px rgba(var(--foreground-divider-rgb), 14%);
  display: block;
  background: var(--foreground-background);
  border-radius: $border-radius;
  overflow: hidden;
}

.ordenable-item-common {
  transition: box-shadow .3s cubic-bezier(0, 0, 0.2, 1);

  &:not(.cdk-drag-preview) {
    transition: box-shadow .3s cubic-bezier(0, 0, 0.2, 1), 250ms;
  }

  &.cdk-drag-placeholder {
    opacity: 0;

    &.visible-placeholder {
      opacity: 0.6;
    }
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.ordenable-item-base {
  @extend .ordenable-item-common;

  &.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: $border-radius;
    color: var(--foreground-text);
    cursor: move;
    @include mat.elevation(3);
  }
}

.ordenable-item {
  @extend .ordenable-item-base;
  padding: 8px 0;
  border-bottom: solid 1px rgba(var(--foreground-divider-rgb), 14%);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: var(--foreground-background);
  font-size: 14px;
  transition: box-shadow .3s cubic-bezier(0, 0, 0.2, 1);
  line-height: mat.line-height($colmeia-app-typography, body-1);

  &:last-child {
    border-bottom: none;
  }

  &.ordenable-header {
    padding: 8px 0;
  }

  .drag-handle {
    padding: 6px 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(var(--primary-default-rgb), 70%);
    transition: color .3s ease-in-out;
    cursor: default;
    user-select: none;

    &:hover:not(&.drag-indicator) {
      cursor: move;
      color: var(--primary-default);
    }

    &:last-child {
      border: none;
    }
  }

  .ordenable-item-content {
    width: 100%;
  }

  .ordenable-item-actions {
    display: flex;
    align-items: center;
    padding-right: 8px;
  }
}

.cdk-drop-list-dragging {
  .ordenable-item,
  .ordenable-item-base{
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
