@use "@angular/material" as mat;
@use "sass:color";
@import "vendors/material/theme_variables";
@import "utilities/variables";
@import "utilities/mixins";
@import "global/utils";

.mat-drawer-container {
  background: get-color-from-pallete($colmeia-foreground, background);
}

.mdc-tab--active {
  opacity: 1 !important;
}

.rounded-tab-header {
  .mat-mdc-tab-header {
    border-radius: 4px;
  }
}

.borderless-tab-header {
  .mat-mdc-tab-header {
    .mat-ink-bar {
      // height: 0;
    }
  }
}

.mat-hint {
  &.error {
    color: var(--warn-default);
  }
}

.ordenable-chip {
  &.mat-standard-chip {
    cursor: move;
  }

  &.cdk-drag-placeholder {
    opacity: 0;
  }

  &.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.centered-mat-chip-grid {
  .mat-chip-grid-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.cdk-drop-list-dragging {
  .ordenable-chip:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.mat-button-toggle-group {

  .mat-pseudo-checkbox {
    display: none ;
  }

  &.dense {
    .mat-button-toggle-label-content {
      line-height: 32px;
    }
  }

  &.densex2 {
    .mat-button-toggle-label-content {
      line-height: 28px;
      padding: 0 8px;
    }
  }

  &.densex3 {
    .mat-button-toggle-label-content {
      line-height: 24px;
      padding: 0 8px;
    }
  }

  &.high-contrast {
    .mat-button-toggle-label-content {
      color: var(--foreground-secondary-text);
    }

    .mat-button-toggle-checked {
      .mat-button-toggle-label-content {
        color: var(--foreground-text);
      }
    }
  }

  &.primary-active {
    background: var(--primary-default-contrast);
    color: rgba(var(--primary-default-rgb), 70%);

    .mat-button-toggle {
      transition: background 0.2s ease-in-out;

      &.mat-button-toggle-checked {
        font-weight: 500;
        background: var(--primary-default);

        .mat-button-toggle-label-content {
          color: var(--primary-default-contrast);
        }
      }
    }
  }

  &.accent-active {
    background: var(--accent-default-contrast);
    color: rgba(var(--accent-default-rgb), 70%);

    .mat-button-toggle {
      transition: background 0.2s ease-in-out;

      &.mat-button-toggle-checked {
        font-weight: 500;
        background: var(--accent-default);

        .mat-button-toggle-label-content {
          color: var(--accent-default-contrast);
        }
      }
    }
  }
}

.mat-mdc-menu-item {
  &.select-mode {
    margin: 0 4px;
    width: calc(100% - 8px);
    border-radius: 4px;

    mat-icon {
      @include mat-icon-size(16px);
      margin-right: 6px;
    }
  }

  &.primary-active {
    background-color: var(--primary-default);
    color: var(--primary-default-contrast);
    span {
      color: var(--primary-default-contrast) !important;
    }

    .mat-icon-no-color {
      color: var(--primary-default-contrast);
    }

    font-weight: bold;

    &.stroked {
      background-color: var(--primary-default-contrast);
      color: var(--primary-default);
      span {
        color: var(--primary-default) !important;
      }
      .mat-icon-no-color {
        color: var(--primary-default);
      }

      box-shadow: inset 0 0 0 1px rgba(var(--primary-default-rgb), 14%);
    }

    &:hover {
      background-color: var(--primary-default) !important;
      color: var(--primary-default-contrast) !important;
      span {
        color: var(--primary-default-contrast) !important;
      }
      .mat-icon-no-color {
        color:  var(--primary-default-contrast) !important;
      }
    }
  }

  &.accent-active {
    background-color: var(--accent-default);
    color: var(--accent-default-contrast);

    .mat-icon-no-color {
      color: var(--accent-default-contrast);
    }

    font-weight: bold;

    &:hover {
      background-color: var(--accent-default);
    }

    &.stroked {
      background-color: inherit;
      color: var(--accent-default);

      .mat-icon-no-color {
        color: var(--accent-default);
      }

      box-shadow: inset 0 0 0 1px rgba(var(--accent-default-rgb), 14%);
    }
  }
}

.mat-mdc-menu-panel {
  min-height: 52px !important;
  border-radius: 4px;
  background-color: #0f0f0f;

  &.dense {
    .mat-mdc-menu-item {
      height: 34px;
      line-height: 34px;
      min-height: 34px;
    }
  }

  &.wrap-label {
    .mat-mdc-menu-item {
      height: auto;
      min-height: 48px;
      line-height: 105%;
      display: flex;
      align-items: center;
      white-space: pre-line;
    }

    &.dense {
      .mat-mdc-menu-item {
        min-height: 36px;
        padding: 10px 16px;
      }
    }
  }

  &.no-max-width {
    max-width: none;
  }
}

.mat-dialog-content {
  max-height: 75vh !important;
}

mat-slider {
  &:hover,
  &:focus {
    .mat-mdc-slider-visual-thumb,
    .mdc-slider__thumb--with-indicator,
    .mdc-slider__thumb--focused
    {
      background-color: transparent;

      .mat-mdc-focus-indicator {
        background-color: transparent;
      }
    }
  }

  &.visible-label {

    .mdc-slider__value-indicator {
      transform: scale(1);
   }

    &.cold .mat-slider-thumb-label,
    &.cold .mdc-slider__value-indicator-container {
      // background-color: var(--info-default);
    }

    &.warm .mat-slider-thumb-label,
    &.warm .mdc-slider__value-indicator-container {
      // background-color: var(--alert-default);
    }

    &.hot .mat-slider-thumb-label,
    &.hot .mdc-slider__value-indicator-container {
      // background-color: var(--warn-default);
    }

    &.mat-accent {
      .mat-slider-thumb-label,
      .mdc-slider__value-indicator-container {
        // background: var(--accent-default) !important;
      }
    }

    .mdc-slider__value-indicator-container {
      pointer-events: auto;
    }

    .mat-slider-thumb,
    .mdc-slider__thumb-knob {
      transform: scale(0.7) !important;
      left: 30%;
      top: 30%;
    }

    .mat-slider-thumb-label,
    .mdc-slider__value-indicator-container {
      // transform: rotate(45deg) !important;
      // border-radius: 50% 50% 0 !important;
    }

    .mat-slider-thumb-label-text,
    .mdc-slider__value-indicator,
    .mdc-slider__value-indicator-text {
      opacity: 1 !important;
    }
  }
}

.mat-icon-button {
  > .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-snack-bar-container {
  &.snackbar-w-lines {
    white-space: pre-wrap;
  }

  &.high-contrast {
    background-color: #0f0f0f;
  }
}

.mat-mini-fab {
  box-shadow: none !important;
}

mat-slide-toggle {
  &.dense {
    .mat-slide-toggle-bar,
    .mdc-switch,
    .mdc-switch__track {
      width: 32px;
      height: 12px;
      top: 0;
    }

    .mat-slide-toggle-thumb-container,
    .mat-slide-toggle-thumb,
    .mdc-switch__handle {
      width: 16px !important;
      height: 16px !important;

      .mdc-switch__icon {
        width: 14px;
      }
    }

    .mat-slide-toggle-thumb-container {
      top: -2px;
    }

    .mat-slide-toggle-content,
    .mdc-label {
      font-size: $smaller-font;
      font-weight: 500;
      width: max-content;
      margin-right: 6px;
    }

    .mdc-switch--selected .mdc-switch__handle-track {
      transform: translateX(130%) !important;
  }
  }

  &.dense-2x {
    transform: scale(0.85);

    .mdc-label {
      font-size: 1rem;
      padding-left: 6px;
    }

    // .mat-slide-toggle-bar {
    //   width: 20px;
    //   height: 8px;
    //   top: 0;
    // }

    // .mat-slide-toggle-thumb-container,
    // .mat-slide-toggle-thumb{
    //   width: 12px;
    //   height: 12px;
    // }

    // .mat-slide-toggle-thumb-container {
    //   top: -2px;
    // }

    // .mat-slide-toggle-content {
    //   font-size: $smaller-font;
    //   font-weight: 500;
    // }

    // &.mat-checked {
    //   .mat-slide-toggle-thumb-container {
    //     transform: translate3d(10px, 0, 0) !important;
    //   }
    // }
  }
}

.mat-list {
  &.overflow-visible {
    .mat-list-base,
    .mat-list-item {
      .mat-line,
      .mat-list-text {
        overflow: visible !important;
      }
    }
  }
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    white-space: pre-wrap;
    color: var(--primary-default-contrast);
    background: var(--primary-default);
  }

  @include mq('sm', true) {
    display: none;
  }
}

mat-tab-group {
  > .mat-mdc-tab-header {
    > .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        > .mat-mdc-tab-labels {
          border-bottom: 1px solid var(--foreground-divider);

          > .mat-mdc-tab {
            fill: var(--foreground-text);

            &:hover {
              .mdc-tab__ripple::before {
                background-color: none;
                opacity: 0;
              }

              .mdc-tab-indicator {
                background-color: rgba(7, 24, 22,.04);
              }
            }

            .mdc-tab-indicator {
              // visibility: hidden;
              left: auto !important;
              right: auto !important;
              transform: translateX(0) scaleX(1) !important;
            }

            .mat-mdc-tab-ripple {
              display: none;
            }
          }
        }
      }
    }
  }
}

@mixin mat-boxed-builder-color($color) {
  > .mat-mdc-tab-header {
    > .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        > .mat-mdc-tab-labels {
          > .mat-mdc-tab {
            color: var(--#{$color}-50-contrast);
            background-color: rgba(var(--#{$color}-50-rgb), 74%);
            box-shadow: inset 0 0 1px 1px rgba(var(--#{$color}-100-rgb), 80%);

            &:hover {
              opacity: 1;
            }

            &.mat-mdc-tab-label-highlight {
              background-color: white;
            }

            &.mdc-tab--active {
              color: var(--#{$color}-default-contrast);
              background-color: var(--#{$color}-default);
              box-shadow: none;

              .mdc-tab__text-label {
                color: var(--#{$color}-default-contrast);
              }
            }
          }
        }
      }
    }
  }
}

mat-tab-group {
  &.full-body-height {
    > .mat-mdc-tab-body-wrapper {
      height: 100%;
    }
  }

  &.content-full-height {
    .mat-mdc-tab-body-content {
      height: 100%;
    }
  }

  &.body-nice-scroll {
    .mat-mdc-tab-body-content {
      @extend .nice-scroll;
    }
  }

  &.body-y-scroll-lock {
    .mat-mdc-tab-body-content {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  /**
   * Boxed tabs
   */
  &.boxed-tabs {

    &.indexed-search-fields-config {
      .mat-mdc-tab {
        flex-grow: 0 !important;
      }
    }

    > .mat-mdc-tab-header {
      margin: 0 -2px;

      > .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          > .mat-mdc-tab-labels {
            padding: 4px;
            border: none;

            > .mat-mdc-tab {
              justify-content: center;
              border-radius: $border-radius;
              margin-bottom: $border-radius;
              opacity: 0.8;
              fill: var(--foreground-text);
              min-width: 160px;

              &:hover {
                opacity: 1;

                .mdc-tab__ripple::before {
                  background-color: none;
                  opacity: 0;
                }

                .mdc-tab-indicator {
                  background-color: unset;

                }
              }

              .mdc-tab-indicator {
                // visibility: hidden;
                width: 0px !important;
                left: auto !important;
                right: auto !important;
                transform: unset;
              }

              .mat-mdc-tab-ripple {
                display: none;
              }

              &:not(.mdc-tab-indicator--active) {
                .mdc-tab__text-label {
                  color: var(--primary-default);
                }
              }
            }
          }
        }
      }

      mat-ink-bar {
        // display: none;
      }
    }

    &.mat-primary {
      @include mat-boxed-builder-color("primary");
    }

    &.mat-accent {
      @include mat-boxed-builder-color("accent");
    }

    .mat-mdc-tab-nav-bar,
    .mat-mdc-tab-header {
      border: none;
    }

    &.boxed-dense {
      > .mat-mdc-tab-header {
        > .mat-mdc-tab-label-container {
          .mat-mdc-tab-list {
            > .mat-mdc-tab-labels {
              // padding: 0;

              > .mat-mdc-tab {
                padding: 12px;
                height: 36px;
                margin: 2px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  /**
   * Vertical Tab
  */
  &.vertical-tab {
    @extend .boxed-tabs;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;

    > .mat-mdc-tab-header {
      > .mat-mdc-tab-label-container {
        margin: 8px 0;

        .mat-mdc-tab-list {
          @include nice-scroll(
            $color: var(--foreground-text),
            $color-hover: var(--foreground-text)
          );
          overflow: auto;

          > .mat-mdc-tab-labels {
            flex-direction: column;
            padding: 0 4px;
          }
        }
      }
    }

    > .mat-mdc-tab-body-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    mat-tab-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  /**
   * Tabs wrap
  */
  &.tabs-wrap {
    > .mat-mdc-tab-header {
      > .mat-mdc-tab-header-pagination {
        display: none;
      }

      > .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          margin-left: -4px;
          margin-right: -4px;

          > .mat-mdc-tab-labels {
            flex-wrap: wrap;

            > .mat-mdc-tab {
              margin: 4px;
            }
          }
        }
      }
    }
  }
}

mat-checkbox {
  &.centered-label {
    .mat-checkbox-label {
      display: flex;
      align-items: center;
    }
  }
}

.mat-input-element {
  caret-color: unset;
  box-sizing: border-box !important;
}

button {
  &[mat-icon-button],
  &[mat-stroked-button],
  &[mat-flat-button] {
    &:hover {
      filter: brightness(1.1);
    }
    white-space: nowrap;
  }
}

@keyframes mat-slide-toggle-loading-container {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.mat-slide-toggle {
  &.loading {
    pointer-events: none !important;
    cursor: wait;

    .mat-slide-toggle-thumb-container {
      transform: translate3d(8.5px, 0, 0) !important;

      &:before {
        $diameter: calc(100% + 5px);
        content: "";
        z-index: 2;
        width: $diameter;
        height: $diameter;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        border: 4px solid;
        border-top-color: transparent;
        border-radius: 50%;
        animation: mat-slide-toggle-loading-container 2s linear infinite;
      }
    }

    &.mat-checked {
      &.mat-primary {
        .mat-slide-toggle-thumb-container:before {
          color: get-color-from-pallete($primary, "A400");
        }
      }

      &.mat-accent {
        .mat-slide-toggle-thumb-container:before {
          color: get-color-from-pallete($accent, default-contrast);
        }
      }
    }
  }
}

@keyframes mat-checkbox-loading-container {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

mat-checkbox {
  &.loadable {
    .mat-checkbox-inner-container {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        border: 2px solid;
        border-bottom-color: transparent;
        top: 50%;
        left: 50%;
        width: 150%;
        height: 150%;
        animation: mat-checkbox-loading-container 2s linear infinite;
        animation-play-state: paused;
        opacity: 0;
        border-radius: 50%;
        transition: 0.2s ease-in-out;
      }

      .mat-checkbox-frame,
      .mat-checkbox-background {
        transition: 0.2s ease-in-out;
      }
    }

    &.loading {
      .mat-checkbox-inner-container {
        &:before {
          opacity: 1;
          animation-play-state: running;
        }

        .mat-checkbox-frame,
        .mat-checkbox-background {
          transform: scale(0.6);
          opacity: 0.8;
        }
      }
    }
  }
}

.mat-form-field-appearance-outline {
  &.white-field {
    color: white;

    mat-label {
      color: white;
    }

    .mat-form-field-outline {
      color: rgb(255 255 255 / 12%);
    }

    .mat-form-field-outline-thick {
      color: rgb(255 255 255 / 50%);
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: white;
      }
    }
  }

  &.dense {
    .mat-form-field-infix,
    .mat-mdc-form-field-infix {
      min-height: unset;
      padding: 0.5em 0 0.5em 0;
    }
    input.mat-input-element {
      position: relative;
      top: -2px;
    }

    .mat-form-field-label {
      margin-top: -0.7em;
    }

    .mat-form-field-label.mat-form-field-empty {
      margin-top: -0.4em;
    }
  }
}

mat-expansion-panel-header {
  mat-panel-title,
  .mat-content,
  &.overflow-visible {
    overflow: visible;
  }
}

mat-checkbox {
  &.mat-mdc-checkbox {
    .mdc-label {
      padding: 14px 14px 14px 0px; //Corrects many checkboxes spacing
      letter-spacing: 0;
      line-height: 20px;
      font-size: 16px; //previously at 100% some checkboxes were still at 14px
      color: rgba(var(--foreground-color-rgb), .9);
    }
  }

  &.dense-checkbox {
    --mdc-checkbox-state-layer-size: 36px !important;

    .mat-internal-form-field {
      height: 24px;
    }
    .mdc-label {
      padding: 4px;
    }
  }

  &.boxed {
    border-radius: 4px;
    width: 100%;

    height: unset !important;

    .mdc-form-field.mat-internal-form-field {
      height: unset !important;
    }

    &.mat-primary {
      background: rgba(var(--primary-default-rgb), 4%);
    }

    &.mat-accent {
      background: rgba(var(--primary-default-rgb), 4%);
    }

    &.mat-warn {
      background: rgba(var(--primary-default-rgb), 4%);
    }

    > .mat-checkbox-layout {
      width: 100%;

      > .mat-checkbox-inner-container {
        margin-left: 0;
      }
    }

    &.boxed-14 {
      > .mat-checkbox-layout {
        padding: 14px;
      }
    }
    &.boxed-19-5 {
      >.mat-checkbox-layout {
        padding: 19.5px;
      }
    }
  }
}

mat-slide-toggle {
  &.boxed {
    border-radius: 4px;
    width: 100%;
    height: initial;

    &.mat-primary {
      background: rgba(var(--primary-default-rgb), 4%);
    }

    &.mat-accent {
      background: rgba(var(--primary-default-rgb), 4%);
    }

    &.mat-warn {
      background: rgba(var(--primary-default-rgb), 4%);
    }
  }

  &.boxed-14 {
    padding: 14px;
  }
  &.boxed-19-5 {
    padding: 19.5px;
  }
}

.full-width-field {
  width: 100%;
}

.mat-mdc-button-base {
  &.hide-overflow {
    > .mdc-button__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.fixed-size-mat-slide-thumb {
  .mat-slider-thumb,
  .mdc-slider__thumb-knob {
    transform: scale(0.7) !important;
    left: 30%;
    top: 30%;
  }


}
.reversed-thumb {
  .mat-slider-thumb-label,
  .mdc-slider__value-indicator-container {
    top: unset;
    bottom: -49px;
    right: -17px;
    width: 35px;
    height: 35px;
  }
}

mat-form-field {
  &.dense {
    .mat-form-field-flex {
      margin-top: -2px;
      padding: 0 0.4em 0 0.75em;
    }

     .mat-form-field-empty {
       .mat-form-field-label {
         margin-top: -0.6em;
       }
     }


     .mat-form-field-empty {
      .mat-form-field-label {
        margin-top: -0.6em;
      }

      .mat-select-value {
        margin-top: -10px;
      }
    }

    .mat-form-field-label {
      &:not(.mat-empty) {
        margin: -0.3rem;
        margin-left: 0;
      }

      &.mat-empty {
        margin-top: -0.6em;
      }
    }
  }

  &.no-padding-bottom {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      > .mat-form-field-underline {
        bottom: 0;
      }
    }

    .mat-mdc-form-field-subscript-wrapper  {
      display: none;
    }
  }

  &.center-field-flex {
    .mat-form-field-flex {
      align-items: center;
    }
  }
}

.custom-badge-warn {
  > .mat-badge-content {
    background: var(--warn-default);
  }
}

.mat-mdc-tooltip.mat-tooltip-info {
  .mdc-tooltip__surface {
    @extend .app-alert-info-darker;
  }
}

.mat-mdc-tooltip.mat-tooltip-success {
  .mdc-tooltip__surface {
    @extend .app-alert-success-darker;
  }
}

.mat-mdc-tooltip.mat-tooltip-warning {
  .mdc-tooltip__surface {
    @extend .app-alert-warning-darker;
  }
}

.mat-mdc-tooltip.mat-tooltip-error {
  .mdc-tooltip__surface {
    @extend .app-alert-error-darker;
  }
}

.mat-form-field.range-datetime-picker {
  .mat-form-field-infix,
  .mat-mdc-form-field-infix {
    width: 210px;

    .mat-input-element {
      font-size: 0.74rem;
      font-weight: 500;
    }
  }
}

.mat-tooltip.safe-var-tooltip {
  @extend .app-alert-info-darker;
  font-size: $smaller-font;
  word-break: keep-all;
}

mat-chip,
.mat-mdc-chip {
  &.special-mat-chip {
    border-radius: $border-radius;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--accent-default-contrast) !important;
    background-color: var(--accent-default) !important;
    padding: 7px 12px;
  }
}

.mat-standard-chip {
  background-color: #e0e0e0;
}

.mat-mdc-chip-row,
.mat-mdc-chip-option {
  .mdc-evolution-chip__action,
  .mdc-evolution-chip__graphic {
    padding: 0 !important;
  }
}


.mat-drawer[style*="visibility: hidden"] {
  display: initial !important;
}

.mat-form-field-appearance-fill.hide-unfocused-underline
  .mat-form-field-underline {
  height: 0;
}

.mat-icon {
  &.mat-info {
    color: var(--info-default);
  }

  &.mat-success {
    color: var(--success-default);
  }

  &.mat-alert {
    color: var(--alert-default);
  }
}

.mat-mdc-standard-chip {

  &.dense {
    padding: 7px 10px;
    min-height: 26px;
    font-size: $smaller-font !important;
    // height: 26px;
    margin: 0px 4px !important;
  }
}

.mat-table.clickable-row {
  .mat-row {
    .mat-cell {
      cursor: pointer;
    }

    &:hover .mat-cell {
      background: get-color-from-pallete($colmeia-foreground, text, 6%);
    }
  }
}

.mat-expansion-panel,
.mat-mdc-card {
  padding: 16px;
  color: rgba(var(--foreground-color-rgb),.9);

  &:not(.stroked-inset) &:not([class*="mat-elevation-z"]) {
    box-shadow: $box-elevation-1 !important;
  }
}

.no-padding {
  padding: 0 !important;
}

.mat-form-field.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px rgba(var(--primary-50-rgb), 40%);
  }

  &:hover {
    .mat-form-field-flex {
      box-shadow: inset 0 0 0 2px var(--primary-50);
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field-invalid {
    .mat-form-field-flex {
      background-color: rgba(var(--warn-50-rgb), 80%);
      box-shadow: inset 0 0 0 2px rgba(var(--warn-500-rgb), 30%);
    }
  }

  &.mat-focused:not(&.mat-form-field-invalid) {
    .mat-form-field-flex {
      box-shadow: inset 0 0 0 2px rgba(var(--primary-100-rgb), 60%);
    }
  }
}

.mat-select-panel .mat-optgroup-label {
  background: primary-color(default, 10%);
  color: primary-color();
  border-radius: 4px;
  line-height: 2em;
  height: 2em;
  margin: 8px;
}

.mat-button-base.wrap {
  padding: 10px 14px;
  line-height: 140%;

  > .mat-button-wrapper {
    white-space: break-spaces;
    height: 140%;
  }
}

.mat-button-base.text-left {
  > .mat-button-wrapper {
    text-align: left;
  }
}

.mat-button-base.button-with-icon-prefix {
  position: relative;
  padding-left: 46px;

  .prefix-icon {
    @include mat-icon-size(16px, true);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 100% !important;
    width: 34px !important;
    line-height: 34px !important;
  }

  &.mat-flat-button {
    &.mat-primary {
      .prefix-icon {
        background-color: rgba(var(--primary-default-contrast-rgb), 10%);
      }
    }

    &.mat-accent {
      .prefix-icon {
        background-color: rgba(var(--accent-default-contrast-rgb), 10%);
      }
    }
  }

  &.mat-stroked-button {
    .prefix-icon {
      left: -1px;
    }

    &.mat-primary {
      .prefix-icon {
        background-color: rgba(var(--primary-default-rgb), 10%);
      }
    }
  }
}

.mat-expansion-panel:not(.legacy) {
  box-shadow: $box-elevation-1 !important;
}

.mat-accordion:not(.legacy) {
  .mat-expansion-panel {
    $corner-elements-border-radius: 8px;

    &:first-of-type {
      border-top-right-radius: $corner-elements-border-radius !important;
      border-top-left-radius: $corner-elements-border-radius !important;
    }

    &:last-of-type {
      border-bottom-right-radius: $corner-elements-border-radius !important;
      border-bottom-left-radius: $corner-elements-border-radius !important;
    }

    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing) {
      border-radius: 2px;
      margin-bottom: 10px;
    }
  }
}

.mat-checkbox {
  .mat-checkbox-layout {
    padding: var(--accessibility-padding);
  }
}

.mat-mdc-selection-list {
  border-radius: var(--border-radius) !important;
  box-shadow: inset 0 0 0 2px var(--primary-50);
  display: block !important;

  &[data-max-selections="1"] {
    .mat-pseudo-checkbox {
      border-radius: 100% !important;
    }

    .mat-pseudo-checkbox-checked {
      &:after {
        top: 0;
        left: 0;
        width: 4px;
        height: 4px;
        background: white;
        border: none;
        border-radius: 100%;
        transform: none;
      }
    }
  }

  .mat-mdc-list-item {
    border-top: 1px solid rgba(var(--primary-50-rgb), 80%);
    border-radius: 0;
    font-size: 1rem;

    &:not(:last-child) {
      margin: 0 !important;
    }
  }
}

.realtime-indicators-clean-table-wrapper {
  font-size: 0.875rem;

  td,
  th {
    border: none;
    padding: 24px 16px;
    text-align: center;
  }

  thead th {
    border-bottom: 1px solid var(--foreground-divider);

    .mat-sort-header-container {
      justify-content: center;
    }
  }

  tbody tr {
    td:first-child {
      text-align: left;
    }
  }
}

.realtime-indicators-queue-table-wrapper {
  tbody tr {
    &:nth-child(odd) td:first-child {
      background-color: #4ec1ac;
    }
  }
}

.realtime-indicators-island-table-wrapper {
  max-height: 500px;
  min-height: 400px;

  thead {
    position: sticky;
    top: 70px;
  }
  thead tr th {
    &:first-child {
      background-color: white;
    }
    &:not(:first-child) {
      background-color: #4ec1ac;
    }
  }
  mat-paginator {
    position: sticky;
    bottom: -32px;
    background-color: white;
    z-index: 1;
    border-top: 1px solid var(--foreground-divider);
  }
}

.realtime-indicators-agents-table-wrapper {
  table {
    border-spacing: 0 2px;

    thead tr th {
      &:not(:first-child) {
        background-color: #4ec1ac;
      }
    }

    tbody tr {
      text-transform: capitalize;

      &.online {
        background-color: #7dd87d;

        &:hover {
          background-color: #7dd87d;
          opacity: 0.9;
        }
      }

      &.ocuppied {
        background-color: #f44336;

        &:hover {
          background-color: #f44336;
          opacity: 0.9;
        }
      }

      &.absent {
        background-color: yellow;

        &:hover {
          background-color: yellow;
          opacity: 0.9;
        }
      }
      &.offline {
        background-color: rgb(185, 185, 185);

        &:hover {
          background-color: rgb(185, 185, 185);
          opacity: 0.9;
        }
      }
    }
  }
}

.mat-mdc-option.mdc-list-item {
  background-color: white;

  &:not(:nth-last-child) {
    border-bottom: 1px solid var(--foreground-divider);
  }
}

.vectors-edit {
  mat-expansion-panel-header {
    background-color: var(--primary-default) !important;

    mat-panel-title {
      font-size: 1.2rem;
      color: white;
    }

    .mat-expansion-indicator::after {
      color: white !important;
    }
  }

  .mat-card-header-text {
    margin: 0;
    width: 100%;
  }

  app-enum-picker mat-form-field {
    padding: 0 !important;
  }
}

.prompts-edit {
  .mat-card-header-text {
    margin: 0;
  }

  mat-slider {
    width: 250px;
  }
}

.generative-config-nlp {
  margin: 16px 0;

  .mat-card-header-text {
    margin: 0;
  }
}

.generativo .fine-tunning {
  mat-slide-toggle.mat-disabled {
    opacity: 1;
  }
}
mat-icon {
  &.light-gray {
    color: var(--primary-300);
  }
}

.mat-form-field-appearance-fill {
  .mat-mdc-text-field-wrapper {
    padding: 2px 10px !important;
    height: 100%;
    outline-width: 0;
    color: var(--foreground-text);
    border-radius: $border-radius;
    // border: 1px solid rgba(var(--foreground-divider-rgb), 14%);
    transition: 0.2s ease-in-out;

    // border-radius: 4px;
    box-shadow: inset 0 0 0 2px rgba(var(--primary-50-rgb), 40%);

    // padding: 8px 34px;
    width: 100%;
    // height: 38px;
    margin: 0;
    background: rgba(var(--foreground-text-rgb), 6%);

    &:hover,
    &:focus {
      border-color: var(--foreground-divider);
    }

    .mat-mdc-form-field-focus-overlay {
      opacity: 0 !important;
    }

    .mat-mdc-floating-label {
      // padding-left: 10px;
    }
  }

  &.no-box-shadow {
    .mat-mdc-text-field-wrapper {
      box-shadow: unset;
    }
  }

}

.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    &.mdc-text-field {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .mdc-line-ripple::before {
    border-bottom: none;
  }
}
.mat-focused {
  .mdc-text-field--focused {
    .mat-mdc-form-field-focus-overlay {

    }

    .mdc-line-ripple--active::after {
      border-bottom-color: var(--accent-400) !important;
    }
  }
}

.mat-mdc-checkbox {
  .mdc-label {
    padding: 14px 14px 14px 4px;
  }
}

.mat-mdc-dialog-container {
  .mdc-dialog__container {
    // display: block;
    // padding: 24px;
    // border-radius: 4px;
    // box-sizing: border-box;
    // overflow: auto;
    // outline: 0;
    // width: 100%;
    // height: 100%;
    // min-height: inherit;
    // max-height: inherit;
    // background: white;
  }

  .mdc-dialog__surface {
    box-shadow: none !important;
    overflow: auto;
  }
}

.placeholder-always-visible::placeholder {
  opacity: 1 !important;
  transition: none !important;
}

.landing-input {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    box-shadow: none;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .mdc-line-ripple::before {
      border-bottom: 1px solid white !important;
    }
  }

  &.mat-focused {
    .mat-form-field-ripple {
      background-color: var(--accent-default);
    }
  }

  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
  }

  .mdc-floating-label.mat-mdc-floating-label {
    font-weight: 700;
    color: white !important;

    &.mdc-floating-label--float-above {
      color: var(--accent-default) !important;
    }
  }

}

.mat-mdc-mini-fab {
  box-shadow: none !important;
}

.mat-mdc-radio-group {

  &.conversations-filter {

    mat-radio-button {
      .mdc-radio {
        padding: 0 !important;
      }

      .mdc-label {
        padding-left: 8px !important;
        line-height: normal !important;
      }
    }

  }

  .mat-mdc-radio-button {
    &.mat-mdc-radio-checked {
      .mdc-radio {
        .mdc-radio__background {
          .mdc-radio__outer-circle,
          .mdc-radio__inner-circle {
            border-color: var(--accent-400) !important;
          }
        }
      }
    }
  }
}


.mat-calendar-body-cell {
  /**
  * Fixes a bug caused by using a way too dark color for primary-500: the focus color
  * ends up making the text not readable
  */
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(var(--primary-500-rgb), 0.3);

  &:hover {
    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected).mat-focus-indicator {
      background-color: rgba(7, 24, 22, 0.2) !important;
    }
  }
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  .mat-expansion-panel-header-title {
    .mat-mdc-input-element {
      background-color: transparent !important;
      border-color: transparent !important;
      transition: height 225ms cubic-bezier(0.4, 0, 0.2, 1);
      transition-behavior: normal;
      transition-duration: 0.225s;
      transition-delay: 0s;
    }
  }
}

.standard-form-field.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
    box-shadow: none !important;

    .mat-mdc-input-element {
      border-bottom: 1px solid var(--foreground-divider);
    }

    .mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: 37px !important;
    }

    .mdc-line-ripple::before {
      // border-bottom: 1px solid var(--foreground-divider);
    }

    &:hover {
      // .mdc-line-ripple::before {
      //   border-bottom-color: var(--accent-300) !important;
      // }
      .mat-mdc-input-element {
        border-bottom-color: var(--accent-300) !important;
      }
    }

  }

  &.mat-focused {
    .mdc-line-ripple::after {
      display: none;
    }

    .mat-mdc-input-element {
      border-bottom-color: var(--accent-300) !important;
      border-bottom-width: 2px;
    }
  }
}

//Fixes all menu items
.mat-mdc-menu-item-text {
  font-size: 14px !important;
  letter-spacing: 0 !important;
  color: rgba(var(--foreground-color-rgb), .9) !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  letter-spacing: 0 !important;
  flex-grow: 0 !important;
}

cm-render-options {
  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 1 !important;
  }
}

.mat-mdc-header-row {
  font-size: 12px !important;
}

.mat-mdc-row {
  color: rgba(var(--foreground-color-rgb), .9);
}

.mdc-evolution-chip__text-label {
  user-select: text !important;
}


